
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import { useDicomStore } from '@/store/dicom'
import { useUsersStore } from '@/store/users'
import { useLogStore } from '@/store/log';
import { useTimerStore } from '@/store/timer'
import { usePopupStore } from '@/store/popup'
import { useReadingResultStore } from '@/store/readingResult'
import VueSlider from 'vue-slider-component'
import { nextTick } from 'vue';
import 'vue-slider-component/theme/antd.css'
import { recordLog } from '@/api'

export default defineComponent({
  components: {
    VueSlider
  },
  setup() {
    const dicomStore = useDicomStore();
    const usersStore = useUsersStore();
    const popupStore = usePopupStore();
    const timerStore = useTimerStore();
    const readingResultStore = useReadingResultStore();
    const userId = computed(() => usersStore.getLoginUser?.data.user_id);
    const withCAD = computed(() => usersStore.getWithCAD);
    const dicomInfo = computed(() => dicomStore.getDicomInfo);
    const predictionData = computed(() => dicomStore.getPredictionData);
    const dicomFileUploaded = computed(() => dicomStore.getDicomFileUploaded);
    const showLoadingBox = computed(() => dicomStore.getLoadingState);
    const predictBtnClicked = computed(() => dicomStore.getPredictBtnClicked); // 사용
    const completeScore = ref(0);
    const wc = ref(0);
    const ww = ref(0);
    const tip = ref(0.01);
    const tube = ref(0.01);
    const iw = ref(0.01);
    const windowCenter = computed(() => dicomInfo.value?.window_center || 0);
    const windowWidth = computed(() => dicomInfo.value?.window_width || 0);
    const windowCenterMin = computed(() => dicomInfo.value?.window_center_min || 0);
    const windowCenterMax = computed(() => dicomInfo.value?.window_center_max || 0);
    const windowWidthMin = computed(() => dicomInfo.value?.window_width_min || 0);
    const windowWidthMax = computed(() => dicomInfo.value?.window_width_max || 0);
    const mask = ref(true);
    const heatMap = ref(true); 
    const probability = ref(true); 
    const tipThreshold = computed(() => predictionData.value?.tip_threshold || 0);
    const tubeThreshold = computed(() => predictionData.value?.tube_threshold || 0);
    const imageWeight = computed(() => predictionData.value?.image_weight || 0);
    const comboboxOption = ref([{ name: 'Complete', selected: true }, { name: 'Incomplete', selected: false }]);
    const comboboxSelected = ref('Complete');
    const comboboxOpen = ref(false);
    const windowImgUrl = ref("");
    const dicomImgUrl = ref<any>("");
    const savedCompleteScore = ref(false);

    const validateScore = () => {
      if (completeScore.value !== null) {
        if (completeScore.value < 0) {
          completeScore.value = 0;
        } else if (completeScore.value > 100) {
          completeScore.value = 100;
        }
      }
    }

    const dicomPredict = async (): Promise<void> => {
      if(!predictBtnClicked.value) {
        await dicomStore.dicomPrediction();
        completeScore.value = predictionData.value?.complete_score ?? 0;
        mask.value = true;
        heatMap.value = true;
        probability.value = true;
        setComboboxSelectedVal();
        await changeDicomOption();
        timerStore.startTimer(); // prediction이 끝나면 타이머 재시작
      }
    }

    const changeWindowOption = async (): Promise<void> => {
      await nextTick();
      dicomStore.setLoading(true);
      
      const windowRes = await sendWindowOption();
      const dicomRes = ref(true);

      if (mask.value || heatMap.value || probability.value) {
        dicomRes.value = await sendDicomOption();
      }
      
      if (windowRes && dicomRes) {
        // log (두개 다 됐을 때)
        dicomStore.setImgUrl(windowImgUrl.value, dicomImgUrl.value);
        dicomStore.setLoading(false);
      }
    }

    const changeDicomOption =  async (): Promise<void> => {
      await nextTick();
      const dicomRes = await sendDicomOption();
      if (dicomRes) {
        dicomStore.setImgUrl("", dicomImgUrl.value);
        dicomStore.setLoading(false);
      }
    }

    const selectOption = (idx: number) => {
      for(let i=0; i<comboboxOption.value.length; i++) {
        const option = comboboxOption.value[i];
        option.selected = false;
        if(idx == i) {
          option.selected = true;
          comboboxSelected.value = option.name;
          changeDicomOption();
        }
      }
    }

    const toggleCombobox = () => {
      comboboxOpen.value = !comboboxOpen.value;
    }

    const sendWindowOption = async (): Promise<boolean> => {
      const option = {
        window_center: Number(wc.value),
        window_width: Number(ww.value),
        user_id: userId.value
      }
      
      const res = await dicomStore.applyWindowOption(option);
      if(res) {
        windowImgUrl.value = res;
      }
      return true;
    }

    const sendDicomOption = async (): Promise<boolean> => {
      if(mask.value || heatMap.value || probability.value) {
        dicomStore.setLoading(true);
        const option = {
          window_center: Number(wc.value),
          window_width: Number(ww.value),
          tube_threshold: Number(tube.value),
          tip_threshold: Number(tip.value),
          image_weight: Number(iw.value),
          mask_check: mask.value,
          heatmap_check: heatMap.value,
          probability_map_check: probability.value,
          heatmap_option: comboboxSelected.value,
          user_id: userId.value
        }

        const res = await dicomStore.applyDicomOption(option);
        if(res) {
          dicomImgUrl.value = res;
        }
        return true;
      } else {
        dicomStore.initPredictImgUrl();
        dicomImgUrl.value = "";
      }
      return false;
    }

    const initOption = () => {
      dicomStore.initOption();
      mask.value = predictBtnClicked.value ? true : false;
      heatMap.value = predictBtnClicked.value ? true : false;
      probability.value = predictBtnClicked.value ? true : false;
      dicomImgUrl.value = "";
      setComboboxSelectedVal();
      changeWindowOption();
    }

    const setComboboxSelectedVal = () => {
      if(predictionData.value?.select_heatmap_idx === 0) {
        comboboxSelected.value = "Complete";
      } else if (predictionData.value?.select_heatmap_idx === 1) {
        comboboxSelected.value = "Incomplete";
      }
    }

    const saveScore = () => {
      if(withCAD.value) {
        if(predictBtnClicked.value && !savedCompleteScore.value ) {
          timerStore.stopTimer();
          popupStore.togglePopup('completeScore', true);
          savedCompleteScore.value = true;
          dicomStore.setCompleteScore(completeScore.value);
          dicomStore.insertReadingResult();
          readingResultStore.setShowNewIcon(true);
        }
      } else {
        if(!savedCompleteScore.value) {
          timerStore.stopTimer();
          popupStore.togglePopup('completeScore', true);
          savedCompleteScore.value = true;
          dicomStore.setCompleteScore(completeScore.value);
          dicomStore.insertReadingResult();
          readingResultStore.setShowNewIcon(true);
        }
      }
    }

    watch(dicomInfo, (newVal) => {
      wc.value = newVal?.window_center ?? 0.01;
      ww.value = newVal?.window_width ?? 0.01;
    });

    watch(predictionData, (newVal) => {
      tip.value = newVal?.tip_threshold ?? 0.01;
      tube.value = newVal?.tube_threshold ?? 0.01;
      iw.value = newVal?.image_weight ?? 0.01;
      dicomImgUrl.value = newVal?.imageUrl;
    });

    watch(dicomFileUploaded, (newVal) => {
      if(!newVal) { // delete 버튼 클릭 시 값 초기화
        mask.value = false;
        heatMap.value = false;
        probability.value = false;
        completeScore.value = 0;
        savedCompleteScore.value = false;
      }
    });

    onMounted(() => {
      nextTick(() => {
        mask.value = false;
        heatMap.value = false;
        probability.value = false;
      });
    });

    return {
      dicomFileUploaded,
      intMask: [0, 4095],
      floatMask: [0.01, 1],
      windowCenter,
      windowWidth,
      windowCenterMin,
      windowCenterMax,
      windowWidthMin,
      windowWidthMax,
      tipThreshold,
      tubeThreshold,
      imageWeight,
      mask,
      heatMap,
      probability,
      comboboxOption,
      comboboxSelected,
      comboboxOpen,
      selectOption,
      toggleCombobox,
      dicomInfo,
      sendDicomOption,
      sendWindowOption,
      wc,
      ww,
      tip,
      tube,
      iw,
      predictionData,
      initOption,
      changeWindowOption,
      changeDicomOption,
      showLoadingBox,
      dicomPredict,
      predictBtnClicked,
      completeScore,
      withCAD,
      saveScore,
      validateScore,
      savedCompleteScore
    };
  },
});
