import { defineStore } from 'pinia';
import { ref } from 'vue';
import { dicomUpload, originVisualization, applyVisualizationOptions, prediction, exportFile, insertReadingResult, dicomTag } from '../api';

interface FileDataState {
  files: File[]; // 사용자가 업로드한 파일
  uploadProgress: number;
  dicomFileUploaded: boolean;
  initData: InitData;
  dicomInfo: DicomInfo;
  predictionData: PredictionData;
  predictBtnClicked: boolean;
  loading: boolean;
  completeScore: number;
  dicomTag: DicomTagItem[];
}

export interface DicomTagItem {
  tag: string;
  description: string;
  vr: string;
  vm: number;
  value: string;
}

interface InitData {
  blob: Blob | null;
  imageUrl: string;
  predictionImageUrl: string;
  window_center: number;
  window_width: number;
  image_weight: number;
  tip_threshold: number;
  tube_threshold: number;
  select_heatmap_idx: number;
}

interface DicomInfo {
  blob: Blob | null;
  imageUrl: string;
  window_center_min: number;
  window_center_max: number;
  window_width_min: number;
  window_width_max: number;
  window_center: number;
  window_width: number;
}

interface PredictionData {
  blob: Blob | null;
  imageUrl: string;
  complete_score: number;
  incomplete_score: number;
  image_weight: number;
  tip_threshold: number;
  tube_threshold: number;
  select_heatmap_idx: number;
}

export const useDicomStore = defineStore('dicom', {
  state: (): FileDataState => ({
    files: [],
    uploadProgress: 0,
    dicomFileUploaded: false,
    initData: {
      blob: null,
      imageUrl: "",
      predictionImageUrl: "",
      window_center: 0,
      window_width: 0,
      image_weight: 0.01,
      tip_threshold: 0.01,
      tube_threshold: 0.01,
      select_heatmap_idx: 0
    },
    dicomInfo: {
      blob: null,
      imageUrl: "",
      window_center_min: 0,
      window_center_max: 0,
      window_width_min: 0,
      window_width_max: 0,
      window_center: 0,
      window_width: 0,
    },
    predictionData: {
      blob: null,
      imageUrl: "",
      complete_score: 0,
      incomplete_score: 0,
      image_weight: 0.01,
      tip_threshold: 0.01,
      tube_threshold: 0.01,
      select_heatmap_idx: 0
    },
    predictBtnClicked: false,
    loading: false,
    completeScore: 0,
    dicomTag: [] as DicomTagItem[]
  }),
  actions: {
    setFile(file: File[]) {
      this.files = file;
    },
    async dicomUpload() {
      const dicomData = await dicomUpload((progress) => {
        this.uploadProgress = progress;
      });

      if (dicomData != undefined) {
        this.dicomInfo = dicomData as DicomInfo;
        this.initData.blob = dicomData.blob;
        this.initData.imageUrl = dicomData.imageUrl;
        this.initData.window_center = dicomData.window_center;
        this.initData.window_width = dicomData.window_width;

        this.getDicomTagData();
        return true
      } else {
        return false
      }
    },
    async getDicomTagData() {
      this.dicomTag = await dicomTag();
    },
    setDicomFileUploaded(uploaded: boolean) {
      this.dicomFileUploaded = uploaded;
    },
    async dicomPrediction() {
      this.loading = true;
      const predictionData = await prediction();
      if (predictionData) {
        this.predictionData = predictionData as PredictionData;
        this.initData.image_weight = predictionData.image_weight;
        this.initData.tip_threshold = predictionData.tip_threshold;
        this.initData.tube_threshold = predictionData.tube_threshold;
        this.initData.select_heatmap_idx = predictionData.select_heatmap_idx;

        this.loading = false;
        this.predictBtnClicked = true;
      }
    },
    initFileDataState() {
      this.files = [];
      this.uploadProgress = 0;
      this.dicomFileUploaded = false;
      this.dicomInfo = {
        blob: null,
        imageUrl: "",
        window_center_min: 0,
        window_center_max: 0,
        window_width_min: 0,
        window_width_max: 0,
        window_center: 0,
        window_width: 0,
      };
      this.predictionData = {
        blob: null,
        imageUrl: "",
        complete_score: 0,
        incomplete_score: 0,
        image_weight: 0.01,
        tip_threshold: 0.01,
        tube_threshold: 0.01,
        select_heatmap_idx: 0
      };
      this.initData = {
        blob: null,
        imageUrl: "",
        predictionImageUrl: "",
        window_center: 0,
        window_width: 0,
        image_weight: 0.01,
        tip_threshold: 0.01,
        tube_threshold: 0.01,
        select_heatmap_idx: 0
      }
      this.uploadProgress = 0;
      this.loading = false;
      this.predictBtnClicked = false;
      this.dicomTag = [];
    },
    initOption() {
      const initDicomInfo = {
        blob: null,
        imageUrl: this.initData.imageUrl,
        window_center_min: this.dicomInfo.window_center_min,
        window_center_max: this.dicomInfo.window_center_max,
        window_width_min: this.dicomInfo.window_width_min,
        window_width_max: this.dicomInfo.window_width_max,
        window_center: this.initData.window_center,
        window_width: this.initData.window_width,
      };
      const initPredictionData = {
        blob: null,
        // imageUrl: this.initData.predictionImageUrl,
        imageUrl: "",
        complete_score: this.predictionData.complete_score,
        incomplete_score: this.predictionData.incomplete_score,
        image_weight: this.predictionData.image_weight,
        tip_threshold: this.predictionData.tip_threshold,
        tube_threshold: this.predictionData.tube_threshold,
        select_heatmap_idx: this.initData.select_heatmap_idx
      };
      this.dicomInfo = initDicomInfo;
      this.predictionData = initPredictionData;
    },
    initPredictImgUrl() {
      this.predictionData.imageUrl = "";
    },
    async applyWindowOption(option: any) {
      const res = await originVisualization(option);
      if (res) {
        this.dicomInfo.blob = res.blob;
        return res.imageUrl;
      }
    },
    async applyDicomOption(option: any) {
      const res = await applyVisualizationOptions(option);
      if (res) {
        this.predictionData.blob = res.blob;
        if(this.initData.predictionImageUrl == '') {
          this.initData.predictionImageUrl = res.imageUrl;
        }
        return res.imageUrl;
      }
    },
    setImgUrl(windowImgUrl: string, dicomImgUrl: string) {
      if (windowImgUrl != "") {
        this.dicomInfo.imageUrl = windowImgUrl;
      }
      if (dicomImgUrl != "") {
        this.predictionData.imageUrl = dicomImgUrl;
      }
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    async handleExportFile(target: string, format: string) {
      await exportFile(target, format);
    },
    setPredictBtnClicked(clicked: boolean) {
      this.predictBtnClicked = clicked;
    },
    async insertReadingResult() {
      await insertReadingResult();
    },
    setCompleteScore(score: number) {
      this.completeScore = score;
    },
  },
  getters: {
    getFile(state): File[] {
      return state.files;
    },
    getUploadProgress(state): number {
      return state.uploadProgress;
    },
    getDicomInfo(state): DicomInfo | null {
      return state.dicomInfo;
    },
    getDicomFileUploaded(state): boolean {
      return state.dicomFileUploaded;
    },
    getPredictionData(state): PredictionData | null {
      return state.predictionData;
    },
    // getPredictBtnActiveState(state): boolean {
    //   return state.predictBtnActive;
    // },
    getLoadingState(state): boolean {
      return state.loading;
    },
    getPredictBtnClicked(state): boolean {
      return state.predictBtnClicked;
    },
    getCompleteScore(state): number {
      return state.completeScore;
    },
    getDicomTag(state): any {
      return state.dicomTag;
    }
  },
});
