
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import { useDicomStore } from '@/store/dicom'
import { usePopupStore } from '@/store/popup'
import { useUsersStore } from '@/store/users'
import { useReadingResultStore } from '@/store/readingResult'

export default defineComponent({
  components: {
  },
  setup() {
    const dicomStore = useDicomStore();
    const usersStore = useUsersStore();
    const readingResultStore = useReadingResultStore();
    const dicomInfo = computed(() => dicomStore.getDicomInfo);
    const predictionData = computed(() => dicomStore.getPredictionData);
    const dicomFileUploaded = computed(() => dicomStore.getDicomFileUploaded);
    const withCAD = computed(() => usersStore.getWithCAD);
    const showOrigin = ref(false);
    const dicomFile = computed(() => dicomStore.getFile);
    const readingResultList = computed(() => readingResultStore.getReadingResultList);
    const setShowNewIcon = computed(() => readingResultStore.getShowNewIcon);

    const deleteDicomInfo = () => {
      if(dicomFileUploaded.value) {
        dicomStore.initFileDataState();
      }
    }

    const handleExportClick = () => {
      if (dicomFileUploaded.value) {
        usePopupStore().togglePopup('export', true);
      }
    }

    const handleDicomTagClick = () => {
      if (dicomFileUploaded.value) {
        usePopupStore().togglePopup('dicomTag', true);
      }
    }

    const handleRstListClick = () => {
      usePopupStore().togglePopup('resultList', true);
    }

    watch(predictionData, (newVal) => {
    });

    onMounted(() => {
      readingResultStore.setShowNewIcon(false);
    });

    return {
      dicomInfo,
      dicomFileUploaded,
      showOrigin,
      dicomFile,
      predictionData,
      deleteDicomInfo,
      handleExportClick,
      handleRstListClick,
      withCAD,
      readingResultList,
      setShowNewIcon,
      handleDicomTagClick
    };
  },
});
