import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

interface PopupState { // popup name
  export: boolean;
  joinFail: boolean;
  joinComplete: boolean;
  resultList: boolean;
  logoutAlert: boolean;
  deleteConfirm: boolean;
  completeScore: boolean;
  dicomTag: boolean;
}

export const usePopupStore = defineStore('popup', {
  state: (): PopupState => ({
    export: false,
    joinFail: false,
    joinComplete: false,
    resultList: false,
    logoutAlert: false,
    deleteConfirm: false,
    completeScore: false,
    dicomTag: false
  }),
  actions: {
    togglePopup(popupName: keyof PopupState, opened: boolean) {
      if (Object.keys(this.$state).includes(popupName)) {
        this[popupName] = opened;
      } else {
        console.warn(`No such popup: ${popupName}`);
      }
    },
    resetPopups() { // 모든 팝업을 false로 초기화
      Object.keys(this.$state).forEach((key) => {
        this[key as keyof PopupState] = false;
      });
    }
  },
  getters: {
    getIsOpen: (state) => (popupName: keyof PopupState) => {
      if (popupName in state) {
        return state[popupName];
      } else {
        console.warn(`No such popup: ${popupName}`);
        return false;
      }
    },
  },
});
