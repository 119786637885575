
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePopupStore } from '@/store/popup'
import { useDicomStore, DicomTagItem } from '@/store/dicom'

export default defineComponent({
  components: {
  },
  props: {
  },
  setup(props, { emit }) {
    const popupStore = usePopupStore();
    const dicomStore = useDicomStore();
    const dicomTag = computed(() => dicomStore.getDicomTag);
    const searchText = ref('');
    const searchRes = ref();

    const closePopup = () => {
      popupStore.togglePopup('dicomTag', false);
    }

    const handleConfirmClick = async (): Promise<void> => {
      closePopup();
    }

    const searchDicomTag = () => {
      searchRes.value = searchDicomTags();
    }

    const searchDicomTags = () => {
      return dicomTag.value.filter((item: DicomTagItem) =>
        item.description.toLowerCase().includes(searchText.value.toLowerCase())  // 대소문자 무시 검색
      );
    }

    onMounted(() => {
      searchDicomTag();
    });

    return {
      closePopup,
      handleConfirmClick,
      dicomTag,
      searchText,
      searchDicomTag,
      searchRes
    };
  },
});
