
import { defineComponent, computed, onMounted } from 'vue';
// import Main from "../components/Main.vue";
import UserInfo from "../components/UserInfo.vue";
import Logo from "../components/Logo.vue";
import DicomFileUpload from "../components/DicomFileUpload.vue";
import DicomOption from "../components/DicomOption.vue";
import DicomViewer from "../components/DicomViewer.vue";
import ExportPopup from "../components/popup/ExportPopup.vue";
import ResultListPopup from "../components/popup/ResultListPopup.vue";
import LogoutAlertPopup from "../components/popup/LogoutAlertPopup.vue";
import DeleteConfirmPopup from "../components/popup/DeleteConfirmPopup.vue";
import CompleteScoreSavePopup from "../components/popup/CompleteScoreSavePopup.vue";
import DicomTagPopup from "../components/popup/DicomTagPopup.vue";
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login'
import { useUsersStore } from '@/store/users'
import { useDicomStore } from '@/store/dicom'
import { usePopupStore } from '@/store/popup'
import { useReadingResultStore } from '@/store/readingResult'

export default defineComponent({
  components: {
    // UserInfo,
    Logo,
    DicomFileUpload,
    DicomOption,
    DicomViewer,
    ExportPopup,
    ResultListPopup,
    LogoutAlertPopup,
    DeleteConfirmPopup,
    CompleteScoreSavePopup,
    DicomTagPopup
  },
  setup() {
    const router = useRouter();
    const dicomStore = useDicomStore();
    const usersStore = useUsersStore();
    const popupStore = usePopupStore();
    const readingResultStore = useReadingResultStore();
    const dicomFileUploaded = computed(() => dicomStore.getDicomFileUploaded);
    const exportPopupIsOpen = computed(() => popupStore.getIsOpen('export'));
    const resultListPopupIsOpen = computed(() => popupStore.getIsOpen('resultList'));
    const logoutAlertPopupIsOpen = computed(() => popupStore.getIsOpen('logoutAlert'));
    const deleteConfirmPopupIsOpen = computed(() => popupStore.getIsOpen('deleteConfirm'));
    const completeScorePopupIsOpen = computed(() => popupStore.getIsOpen('completeScore'));
    const dicomTagPopupIsOpen = computed(() => popupStore.getIsOpen('dicomTag'));

    const logout = async (): Promise<any> => {
      dicomStore.initFileDataState();
      const res = await useLoginStore().logout();
      if(res === "Success") {
        router.push({ name: 'login' });
        usersStore.removeUserData();
        dicomStore.initFileDataState();
      } else {
        console.log('LOGOUT FAIL');
      }
    }

    onMounted(async () => {
      popupStore.resetPopups();
      await usersStore.getUserData();
      readingResultStore.setReadingResultList();
    });

    return {
      logout,
      dicomFileUploaded,
      exportPopupIsOpen,
      resultListPopupIsOpen,
      logoutAlertPopupIsOpen,
      deleteConfirmPopupIsOpen,
      completeScorePopupIsOpen,
      dicomTagPopupIsOpen
    };
  },
});
